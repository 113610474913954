// icon:circle-check | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from "react";

function IconCircleCheck(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="#008190"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256zm113-303L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
    </svg>
  );
}

export default IconCircleCheck;
