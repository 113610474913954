import './App.css';
import { VStack, Box, Text, Center, HStack, Button, Input, Image, FormControl, FormLabel, FormErrorMessage, useDisclosure, Modal, ModalOverlay, ModalFooter, ModalCloseButton, ModalContent, ModalHeader, ModalBody, useToast } from '@chakra-ui/react';
import IconCircleCheck from './CircleCheckArrow';
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
const textStyle = {
  color: 'white',
}

const subTitleStyle = {
  fontSize: 'xl',
  color: 'white',
  fontWeight: 'bold'

}

function App() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [response, setResponse] = useState(null);
  const toast = useToast()

  useEffect(() => {
    console.log(searchParams.get("vendorId"))
    console.log(searchParams.get("token"))
    console.log(searchParams.get("target"))
  }, []);

  const postData = async (values) => {
    try {
      const response = await fetch(decodeURIComponent(searchParams.get("target")), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values, null, 2)
      });

      const data = await response.json();
            onOpen()

      setResponse(data);
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error',
        description: "Unfortunately there was an error submitting your details",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  };

  function onSubmit(values) {
    return new Promise((resolve) => {
      setTimeout(() => {
        values.vid = searchParams.get("vendorId")
        values.token = searchParams.get("token")
        console.log(values)
        postData(values)
        resolve()
      }, 3000)
    })
  }

  // const handleSubmit = (data) => {
  //   console.log(data); // This will log the form data when the form is submitted
  // };
  return (
    <VStack h="100vh" bgColor={"#121212"}>
      <Box w="100%" h="15%" maxH={"100px"} bgColor={'#008190'}>
        <Center w="100%" h="100%">
          <Text color={"white"} letterSpacing={"10px"} fontSize={"30"} fontWeight={'extrabold'}>MYPROTEIN</Text>
        </Center>
      </Box>
      <Box h="85%" w="100%">
        <Center>
        <VStack px="20px" maxW="500px">
          <Box bgGradient='linear(to-b, #272727, #121212)' boxShadow="0px 10px 50px #00C5DB26" mt="20px" px="10px" py="20px" border="2px solid #008190" borderRadius={'20px'}>
            <HStack>
              <VStack alignItems={'flex-start'}>
                <Text {...subTitleStyle}>Almost there!</Text>
                <Text {...textStyle}>Join the MyProtein Club to claim your prize</Text>
              </VStack>
              <Box w="100px">
                <Image
                  src={decodeURIComponent(searchParams.get("image"))}
                  w="100px"
                />
              </Box>
              {/* <Image src="https://www.citypng.com/public/uploads/preview/hd-monster-energy-drink-can-png-31625585292ikok9fnodl.png" /> */}
            </HStack>

          </Box>
          <Text py="10px" alignSelf={"flex-start"} {...subTitleStyle}>MyProtein Club Benefits:</Text>
          <Box mb="30px">
            <HStack>
              <IconCircleCheck />
              <Text {...textStyle}>Members-only discounts on hundreds of our products</Text>
            </HStack>
            <HStack>
              <IconCircleCheck />

              <Text {...textStyle}>First dibs on new products</Text>
            </HStack>
            <HStack>
              <IconCircleCheck />
              <Text {...textStyle}>Free monthly protein tips and recipes</Text>
            </HStack>

          </Box>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <FormControl isInvalid={errors.cname}>
              <FormLabel color={'white'}>First name</FormLabel>
              <Input
                id='cname'
                color={'white'} borderRadius={'8px'}
                placeholder='Full Name'
                {...register('cname', {
                  required: 'Full name is required'
                })}
              />
              <FormErrorMessage>
                {errors.cname && errors.cname.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl  isInvalid={errors.cemail}>
              <FormLabel mt="10px" color={'white'}>Email Address</FormLabel>
              <Input
                id='cemail'
                type="email"
                color={'white'} borderRadius={'8px'}
                placeholder='Email'
                {...register('cemail', {
                  required: 'Email is required'
                })}
              />
              <FormErrorMessage>
                {errors.cemail && errors.cemail.message}
              </FormErrorMessage>
            </FormControl>
            <Button type="submit" colorScheme='brand' w="100%" py="20px" mt="30px"  isLoading={isSubmitting} >
              Join & Claim Prize
            </Button>
          </form>

        </VStack>
        </Center>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered >
        <ModalOverlay />
        <ModalContent mx="20px" bgGradient='linear(to-b, #272727, #121212)' bgColor={"black"} border={"1px solid #008190"} boxShadow="0px 10px 50px #00C5DB26" borderRadius={"20px"}>
          <ModalHeader color={"white"}>Thank you!</ModalHeader>
          <ModalCloseButton  color={"white"}/>
          <ModalBody>
            <Text color={"white"}>Thank you, and enjoy your prize!</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='brand' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}

export default App;
